<template>
  <div class="row my-3 justify-content-center">
    <div class="col-auto bold green-text-dark">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="true" id="acceptTerms" v-model="value">
        <label class="form-check-label" for="flexCheckDefault">
          I accept the terms and conditions.
        </label>
      </div>
    </div>
    <div class="col-auto bold green-text-light cursor p-0">
      <span @click="toggleTerms">Read Here</span>
    </div>
    <ViewTerms v-if="showTerms" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Accept Terms',
  components: {
    ViewTerms: defineAsyncComponent(() => import('../components/ViewTerms.vue'))
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  data () {
    return {
      showTerms: false
    }
  },
  methods: {
    toggleTerms () {
      this.showTerms = !this.showterms
    }
  }
}
</script>
<style scoped>
.onhover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
}
.onhover.show {
  opacity: 1;
}
.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow {
  overflow: visible;
}
.divider1 {
  border-bottom: 1px solid var(--green-color-dark);
}
.border_radius_top {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
}
</style>
